import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Box from "../../../components/box"
import Columns from "../../../components/columns"
import Column from "../../../components/column"
import Bullet from "../../../components/bullet"
import BulletList from "../../../components/bullet-list"
import Breadcrumb from "../../../components/breadcrumb"
import Checkbox from "../../../components/checkbox"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import Heading from "../../../components/heading"
import Image from "../../../components/image"
import Layout from "../../../layouts/default"
import Paper from "../../../components/paper"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"
import StepsBox from "../../../components/steps-box"
import Text from "../../../components/text"
import TextLink from "../../../components/textlink"

const Recipe = ({ title, image, ingredients, children }) => {
  return (
    <Constrain>
      <Paper>
        <Stack>
          <Columns
            alignY="center"
            collapse={[true, false]}
            reverse={[false, true]}
          >
            <Column width={["1/2", "1/3"]}>
              <Box
                sx={{
                  borderWidth: [4, 8],
                  borderStyle: "solid",
                  borderColor: "contrast",
                }}
              >
                {image}
              </Box>
            </Column>
            <Column>
              <Heading as="h3" level={3}>
                {title}
              </Heading>
            </Column>
          </Columns>
          <Box
            sx={{
              p: [3, 6],
              borderWidth: 4,
              borderColor: "border",
              borderStyle: "dashed",
              borderRadius: "lg",
            }}
          >
            <Stack space={6}>
              <Heading as="h4" level={5}>
                Zutaten
              </Heading>
              <BulletList color="whisper" size={[2, 2, 3]}>
                {ingredients}
              </BulletList>
            </Stack>
          </Box>
          <Stack space={6}>
            <Heading as="h4" level={5}>
              Zubereitung
            </Heading>
            <Text as="div" size={[2, 2, 3]}>
              <Stack space={6}>{children}</Stack>
            </Text>
          </Stack>
        </Stack>
      </Paper>
    </Constrain>
  )
}

const WorkshopKalenderPage = () => {
  const data = useStaticQuery(graphql`
    query WorkshopNovemberPageQuery {
      materialien: file(
        relativePath: { eq: "workshops/kalender/november/materialien.jpg" }
      ) {
        ...largeImage
      }
      vorlage: file(relativePath: { eq: "vorlage-november.pdf" }) {
        publicURL
      }
      sandra: file(
        relativePath: { eq: "workshops/kalender/november/sandra.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, layout: CONSTRAINED)
        }
      }
      sara: file(relativePath: { eq: "workshops/kalender/november/sara.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, layout: CONSTRAINED)
        }
      }
      franzi: file(
        relativePath: { eq: "workshops/kalender/november/franzi.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, layout: CONSTRAINED)
        }
      }
      beispiel01: file(
        relativePath: { eq: "workshops/kalender/november/beispiel-1.jpg" }
      ) {
        ...largeImage
      }
      beispiel02: file(
        relativePath: { eq: "workshops/kalender/november/beispiel-2.jpg" }
      ) {
        ...largeImage
      }
      ogImage: file(relativePath: { eq: "og_images/kalender.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 600, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Layout backdrop="workshop-kalender-november">
      <Seo
        title="November"
        description="Um den Sommer willkommen zu heißen, schreiben wir für ihn ein Gedicht in Form eines Akrostichons. Ein Akrostichon ist ein Gedicht, bei dem die Anfangsbuchstaben der Verse ein eigenes Wort ergeben. Passend zur Jahreszeit haben wir uns das Wort S-O-M-M-E-R ausgesucht."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Workshops",
              link: "/workshops",
            },
            {
              title: "Wörter und Bilder",
              link: "/workshops/kalender",
            },
            {
              title: "November",
              link: "/workshops/kalender/november",
            },
          ]}
        />
        <Stack space={[24, 24, 32]}>
          <Stack>
            <PageTitle>November</PageTitle>
            <Paragraph dropcap={true}>
              Im November werden die Tage dunkler und kälter. Wir sitzen
              zuhause, schauen aus dem Fenster und ... trinken eine leckere
              Tasse heiße Schokolade. Jeder hat dafür sein eigenes
              Lieblingsrezept. Bestimmt hast du auch eines. Schreibe es auf und
              bastle ein Rezeptbuch dazu.
            </Paragraph>
          </Stack>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Schokolade auf Rezept
              </Heading>
              <Paragraph>
                In seinen letzten Lebensjahren bekam Hölderlin die heiße
                Schokolade sogar vom Arzt verschrieben. In seiner Pflege-Akte
                heißt es dazu, »daß dem Kranken täglich eine Zwischenkost, in
                Thee, Chocolade oder anderem warmem Getränke bestehend, sodann
                Mittags 1 Glas Wein gereicht werden solle...« Darum kümmerte
                sich die Familie Zimmer, die Hölderlin zu dieser Zeit schon
                lange zur Pflege in ihrem Haus am Tübinger Neckar aufgenommen
                hatte, sehr fürsorglich. Vor allem die Tochter Lotte. Sie hat
                regelmäßig etwas Besonderes für Hölderlin gekocht und gebacken.
              </Paragraph>
              <Paragraph>
                Hast du auch eine ganz spezielle Zubereitungsart, mit der dir
                deine heiße Schokolade am besten schmeckt? Wir verraten dir hier
                unsere Geheimrezepte...
              </Paragraph>
            </Stack>
          </Constrain>

          <Stack>
            <Recipe
              title="Sandras 5-Löffel-Rezept"
              image={
                <Image
                  image={data.sandra.childImageSharp.gatsbyImageData}
                  alt="Sandra"
                />
              }
              ingredients={
                <>
                  <Bullet>1 Tasse Milch</Bullet>
                  <Bullet>5 Löffel Kakao</Bullet>
                </>
              }
            >
              <p>
                Zuerst schütte ich die kalte Milch in meine Tasse. So habe ich
                genau die richtige Menge.
              </p>
              <p>
                Dann kommt die Milch in ein kleines Milchtöpfen und wird auf
                hoher (aber nicht maximaler Hitze) auf dem Herd erwärmt. Um zu
                prüfen, ob die Milch warm genug ist, tippe ich gelegentlich mit
                dem Zeigefinger hinein und koste schon einmal vor.
              </p>
              <p>
                Wenn die Milch die richtige Wärme erreicht hat, nehme ich das
                Kännchen vom Herd und schäume die Milch auf. Um mich dabei nicht
                am heißen Kännchen zu verbrennen, fasse ich es nur mit einem
                Topflappen an.
              </p>
              <p>
                Dann schütte ich die heiße, aufgeschäumte Milch zurück in die
                Tasse.
              </p>
              <p>
                Und nun kommt das Wichtigste dazu: Die magischen 5 Löffel
                Kakao-Pulver. Egal wie groß oder klein die Tasse auch ist: Mit 5
                Löffeln Kakao schmeckt die heiße Schoki einfach am besten!
              </p>
              <p>
                Gut verrühren, sodass sich das Pulver mit der Milch vermischt
                und ein schönes Muster im Schaum entsteht… Und fertig ist der
                Zaubertrank!
              </p>
            </Recipe>
            <Recipe
              title="Saras vegane und glutenfreie Schoki mit Zimt"
              image={
                <Image
                  image={data.sara.childImageSharp.gatsbyImageData}
                  alt="Sara"
                />
              }
              ingredients={
                <>
                  <Bullet>Soja- oder Mandel-Milch</Bullet>
                  <Bullet>Schneebesen</Bullet>
                  <Bullet>Bisschen Zimt (Pulver)</Bullet>
                  <Bullet>Kakaopulver und...</Bullet>
                  <Bullet>Geheime Zutat - Speisestärke!</Bullet>
                  <Bullet>Eine süße Tasse</Bullet>
                </>
              }
            >
              <p>
                In einer Tasse etwas Milch und Speisestärke mischen. Am besten
                mit dem Schneebesen, damit sich alles schön vermengt und sich
                keine Klumpen bilden.
              </p>
              <p>
                Nun nehme ich einen kleinen Kochtopf oder eine Dzezwa, fülle die
                Milch dort hinein und stelle den Herd auf die höchste Stufe.
                Anschließend füge ich 1 Teelöffel Zimt und 4 große Löffel
                Kakaopulver gleichzeitig hinzu und vermische alles. Der Trick
                besteht darin, alles langsam, aber beständig zu verühren,
                während die Milch langsam aufkocht.
              </p>
              <p>
                Wenn die Milch zu sprudeln beginnt, ist das das Zeichen dafür,
                dass sich alles schön dick miteinander vermengt hat und heiß
                genug ist.
              </p>
              <p>Die Schoki ist fertig: In die Tasse gießen und genießen!</p>
            </Recipe>
            <Recipe
              title="Franzis Gewürzschokolade"
              image={
                <Image
                  image={data.franzi.childImageSharp.gatsbyImageData}
                  alt="Franzi"
                />
              }
              ingredients={
                <>
                  <Bullet>250ml warme Hafermilch</Bullet>
                  <Bullet>3 Teelöffel Kakao</Bullet>
                  <Bullet>1 Teelöffel Zucker</Bullet>
                  <Bullet>Zimt nach Belieben</Bullet>
                  <Bullet>1 Prise Pfeffer</Bullet>
                  <Bullet>2 Chilifäden</Bullet>
                  <Bullet>ca. 1 Esslöffel heißes Wasser</Bullet>
                  <Bullet>1 große Tasse</Bullet>
                </>
              }
            >
              <p>
                Ich gebe den Kakao und den Zucker in eine große Tasse und
                vermische beides gleichmäßig miteinander.
              </p>
              <p>
                In der Zwischenzeit koche ich das Wasser auf. Ich nehme dazu
                einen Wasserkocher, du kannst aber auch einen kleinen Topf
                verwenden.
              </p>
              <p>
                Wenn das Wasser gekocht ist, gebe ich etwa einen Esslöffel davon
                in meine Tasse. Ich verrühre nun alles zu einer glatten Masse,
                bis keine Klümpchen mehr zu sehen sind.
              </p>
              <p>
                Nun kommen die Gewürze ins Spiel! Ich vermische den Pfeffer und
                die Chillifäden einfach mit der Kakaomasse. Aber Vorsicht: Sei
                lieber sparsam! Zu viel davon macht den Kakao schnell
                ungenießbar.
              </p>
              <p>
                Nachdem ich die Milch erwärmt habe, gieße ich sie zu den
                restlichen Zutaten in die Tasse und rühre ein paar Mal kräftig
                um.
              </p>
              <p>
                Zu guter Letzt kommt noch etwas Zimt obendrauf und fertig ist
                meine perfekte Schoki für besonders kalte Tage.
              </p>
            </Recipe>
            <Constrain>
              <Paragraph>
                Nun geht es daran, dein eigenes Lieblingsrezept aufzuschreiben.
              </Paragraph>
            </Constrain>
          </Stack>

          <Constrain>
            <Stack>
              <Stack>
                <Heading as="h2" level={2}>
                  Du brauchst...
                </Heading>
                <Stack space={4}>
                  <Checkbox space={3} id="checklist-1">
                    <Text as="span" size={4}>
                      Die Vorlage für dein Rezeptbuch. Du findest sie in deinem
                      Bastelset oder{" "}
                      <TextLink href={data.vorlage.publicURL}>
                        hier zum Ausdrucken
                      </TextLink>
                      .
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-2">
                    <Text as="span" size={4}>
                      Einen Stift zum Schreiben
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-3">
                    <Text as="span" size={4}>
                      Ein paar bunte Farben zum Gestalten
                    </Text>
                  </Checkbox>
                  <Checkbox space={3} id="checklist-4">
                    <Text as="span" size={4}>
                      Einen Klebestift
                    </Text>
                  </Checkbox>
                </Stack>
                <Image
                  image={data.materialien.childImageSharp.gatsbyImageData}
                  alt="Die Anleitung zum Kalenderblatt mit der Rezeptvorlage zum Ausschneiden"
                />
              </Stack>
            </Stack>
          </Constrain>

          <Constrain>
            <Stack>
              <Heading as="h2" level={2}>
                Deine Aufgabe Schritt für Schritt:
              </Heading>
              <Stack space={6}>
                <StepsBox step={1}>
                  <Paragraph>
                    Halte das Rezept für deine Lieblings-Trink-Schokolade auf
                    den dafür vorgesehenen Seiten in der Vorlage fest. Falls du
                    kein eigenes Rezept hast, kannst du auch einen Erwachsenen
                    nach seinem Geheimrezept fragen.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={2}>
                  <Paragraph>
                    Notiere, welche Zutaten man dafür braucht, und erkläre
                    Schritt für Schritt, was man bei der Zubereitung beachten
                    muss.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={3}>
                  <Paragraph>
                    Überlege dir einen Namen für dein Geheimrezept und schreibe
                    ihn auf die Vorderseite deines Rezeptbuchs. Anschließend
                    kannst du die Seite noch passend gestalten und dekorieren.
                  </Paragraph>
                </StepsBox>
                <StepsBox step={4}>
                  <Paragraph>
                    Klebe das Rezeptbuch mit der Rückseite auf deine
                    Kalenderseite. Im nächsten November wartet nun ein Rezept
                    für eine leckere Tasse heiße Schokolade.
                  </Paragraph>
                </StepsBox>
              </Stack>
            </Stack>
          </Constrain>

          <Stack space={6}>
            <Heading as="h2" level={4} align="center">
              So kann deine Kalenderseite aussehen:
            </Heading>
            <Image
              image={data.beispiel02.childImageSharp.gatsbyImageData}
              alt="Detailansicht der fertigen Kalenderseite mit bunten Socken"
            />
          </Stack>

          <Constrain>
            <Stack>
              <Paragraph>
                Wir freuen uns über ein Bild von deiner Kalenderseite! Lass dir
                von einem Erwachsenen helfen und schicke uns ein Foto an{" "}
                <TextLink href="mailto:hoelderlinturm@tuebingen.de">
                  hoelderlinturm@tuebingen.de
                </TextLink>
              </Paragraph>
            </Stack>
          </Constrain>
        </Stack>
      </Stack>
    </Layout>
  )
}

export default WorkshopKalenderPage
